<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>تنظیمات سئوی دسته بندی بلاگ ها</h1>
      </div>
      <b-row>
        <b-col cols="12">
          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="categoriesList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
          >
            <!-- Column: delete -->

            <template #cell(actions)="data">
              <div class="d-flex align-items-center" style="gap: 2rem">
                <div class="d-flex align-items-start" style="gap: 2rem">
                  <b-link
                    :to="{
                      name: 'pages-seo-blog-categories-single',
                      params: { id: data.item.blogCategoryId },
                    }"
                  >
                    <feather-icon
                      size="20"
                      icon="SettingsIcon"
                      class="text-primary"
                    />
                  </b-link>
                </div>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "تنظیمات سئوی دسته بندی بلاگ ها",
  mounted() {
    this.getAllBlogCategories();
  },
  data() {
    return {
      isLoading: false,
      categoriesList: null,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      myTableColumns: [
        {
          key: "blogCategoryId",
          label: "شناسه",
        },
        {
          key: "name",
          label: "نام",
        },
        {
          key: "languagePersianTitle",
          label: "زبان دسته بندی",
        },
        {
          key: "actions",
          label: "عملیات",
        },
      ],
    };
  },
  methods: {
    async getAllBlogCategories() {
      try {
        this.isLoading = true;
        let _this = this;
        let blogCategoryGetAllRequest = new BlogCategoryGetAllRequest(_this);
        blogCategoryGetAllRequest.setRequestParam({
          count: _this.count,
          pageNumber: _this.pageNumber,
        });
        await blogCategoryGetAllRequest.fetch((response) => {
          if (response.isSuccess) {
            console.log(response.data);
            _this.categoriesList = response.data.blogCategories;
            _this.totalCount = response.data.blogCagegoriesCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BCard,
    BRow,
    BFormRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
    BImg,
    BMedia,
    BAvatar,
    BModal,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
  },
};
import {
  BContainer,
  BCard,
  BRow,
  BFormRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BImg,
  BMedia,
  BAvatar,
  BModal,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import { BlogCategoryGetAllRequest } from "@/libs/Api/BlogCategory";
</script>
